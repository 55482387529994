import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

import Intro from './pages/Intro';
import Lps from './pages/Lps';
import Lps_db from './pages/Lps_db';
// import Result from './pages/Lps_advanced';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Intro />} />
          <Route path="/Lps" element={<Lps />} />
          <Route path="/Lps_db" element={<Lps_db />} />
          {/* <Route path="/Lps_advanced" element={<Lps_advanced />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;