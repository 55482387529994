import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/Lps_db.css';

let apiUrl = process.env.REACT_APP_API_URL;

const LpsDb = () => {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchItems(currentPage);
  }, [currentPage]);

  const fetchItems = async (page) => {
    try {
      const response = await axios.get(`${apiUrl}api/items?page=${page}`);
      console.log('API URL:', `${apiUrl}api/items?page=${page}`);
      console.log('API Response:', response.data);
      console.log('First item:', response.data.items[0]);
      setItems(response.data.items);
      setTotalPages(response.data.total_pages);
    } catch (error) {
      console.error('Error fetching items:', error);
      alert('데이터를 불러오는 데 실패했습니다. 서버 상태를 확인해주세요.');
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderUrl = (url) => {
    if (url === "false") {
      return <span className="url-false">URL 없음</span>;
    } else if (url === "ERROR") {
      return <span className="url-error">오류</span>;
    } else if (url === null) {
      return <span className="url-null">미진행</span>;
    } else if (url && typeof url === 'string' && url.trim() !== '') {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer" className="url-link">
          링크
        </a>
      );
    } else {
      return <span className="url-invalid">유효하지 않은 URL</span>;
    }
  };

  return (
    <div className="lps-db-container">
      <h1>LPS Database</h1>
      <table className="lps-table">
        <thead>
          <tr>
            <th>Code</th>
            <th>Item Name</th>
            <th>Model</th>
            <th>Company</th>
            <th>Spec</th>
            <th>Category</th>
            <th>Image URL 1</th>
            <th>Image URL 2</th>
            {/* <th>Memo</th> */}
            <th>Date</th>
            <th>URL</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.code}>
              <td>{item.code}</td>
              <td>{item.item_name}</td>
              <td>{item.item_model}</td>
              <td>{item.company}</td>
              <td>{item.spec}</td>
              <td>{`${item.cat1} > ${item.cat2} > ${item.cat3} > ${item.cat4}`}</td>
              <td>
                {item.img_url1 && (
                  <a href={item.img_url1} target="_blank" rel="noopener noreferrer">
                    Image 1
                  </a>
                )}
              </td>
              <td>
                {item.img_url2 && (
                  <a href={item.img_url2} target="_blank" rel="noopener noreferrer">
                    Image 2
                  </a>
                )}
              </td>
              {/* <td>{item.memo}</td> */}
              <td>{item.date}</td>
              <td>{renderUrl(item.url)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button 
          onClick={() => handlePageChange(currentPage - 1)} 
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>{`Page ${currentPage} of ${totalPages}`}</span>
        <button 
          onClick={() => handlePageChange(currentPage + 1)} 
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default LpsDb;