// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add this to your App.css or create a new CSS file and import it */

.lps-db-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .lps-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .lps-table th,
  .lps-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .lps-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .lps-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .pagination button {
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .pagination button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }`, "",{"version":3,"sources":["webpack://./src/css/Lps_db.css"],"names":[],"mappings":"AAAA,oEAAoE;;AAEpE;IACI,aAAa;IACb,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;;IAEE,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;EACX;;EAEA;IACE,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;EACrB","sourcesContent":["/* Add this to your App.css or create a new CSS file and import it */\n\n.lps-db-container {\n    padding: 20px;\n    max-width: 1200px;\n    margin: 0 auto;\n  }\n  \n  .lps-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-bottom: 20px;\n  }\n  \n  .lps-table th,\n  .lps-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: left;\n  }\n  \n  .lps-table th {\n    background-color: #f2f2f2;\n    font-weight: bold;\n  }\n  \n  .lps-table tr:nth-child(even) {\n    background-color: #f9f9f9;\n  }\n  \n  .pagination {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n  }\n  \n  .pagination button {\n    padding: 5px 10px;\n    background-color: #4CAF50;\n    color: white;\n    border: none;\n    cursor: pointer;\n  }\n  \n  .pagination button:disabled {\n    background-color: #ddd;\n    cursor: not-allowed;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
