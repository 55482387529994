// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */
body {
  font-family: 'Arial', sans-serif;
  /* background-color: #f0f2f5; */
  margin: 0;
  padding: 0;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.intro-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.intro-title {
  color: #333;
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
}

.intro-description {
  color: #666;
  font-size: 1.2em;
  margin-bottom: 30px;
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.intro-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 25px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1em;
}

.intro-button:hover {
  background-color: #45a049;
}

.intro-button span {
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,gCAAgC;EAChC,+BAA+B;EAC/B,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,sCAAsC;EACtC,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["/* App.css */\nbody {\n  font-family: 'Arial', sans-serif;\n  /* background-color: #f0f2f5; */\n  margin: 0;\n  padding: 0;\n}\n\n.App {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n}\n\n.intro-container {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 20px;\n  max-width: 800px;\n  margin: 0 auto;\n}\n\n.intro-title {\n  color: #333;\n  font-size: 2.5em;\n  margin-bottom: 20px;\n  text-align: center;\n}\n\n.intro-description {\n  color: #666;\n  font-size: 1.2em;\n  margin-bottom: 30px;\n  text-align: center;\n}\n\n.button-container {\n  display: flex;\n  justify-content: center;\n  gap: 20px;\n  flex-wrap: wrap;\n}\n\n.intro-button {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 15px 25px;\n  background-color: #4CAF50;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n  font-size: 1em;\n}\n\n.intro-button:hover {\n  background-color: #45a049;\n}\n\n.intro-button span {\n  margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
