import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Intro = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="intro-container">
      <h1 className="intro-title">최저가 검색 솔루션</h1>
      <p className="intro-description">원하는 제품의 최저가를 쉽고 빠르게 찾아보세요!</p>
      
      <div className="button-container">
        <button className="intro-button" onClick={() => handleNavigation('/Lps')}>
          <span>데모</span>
        </button>
        <button className="intro-button" onClick={() => handleNavigation('/Lps_db')}>
          <span>data 확인</span>
        </button>
        {/* <button className="intro-button" onClick={() => handleNavigation('/Lps_advanced')}>
          <span>LPS Advanced</span>
        </button> */}
      </div>
    </div>
  );
};

export default Intro;